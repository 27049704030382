type ENV = 'dev' | 'prod' | 'demo'

export const currentEnv: ENV = "dev";

const env: ENV = currentEnv;

export const Env = env

const API_DEV_URL = "https://casadeliz-api.volkeno-engineering.click";
const API_DEMO_URL = "https://casadeliz-api.volkeno-engineering.click";
const API_PROD_URL = "https://casadeliz-api.volkeno-engineering.click";

export const APP_URL_DEV = "https://casadeliz.volkeno-engineering.click/";
export const APP_URL_DEMO = "https://casadeliz.volkeno-engineering.click/";
export const APP_URL_PROD = "https://casadeliz.volkeno-engineering.click/";

function processApiUrl() {
	if (env === "prod") return API_PROD_URL;
	if (env === "demo") return API_DEMO_URL;
	return API_DEV_URL;
}

function processAppUrl() {
  if (env === "prod") return APP_URL_PROD;
  if (env === "demo") return APP_URL_DEMO;
  return APP_URL_DEV;
}
export const ApiBaseUrl = processApiUrl();
export const AppBaseUrl = processAppUrl();

export const GoogleApikey = "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ";
export const IntechApikey = "98AC5CAD-BF49-4DC5-8D2C-335298D596D4";
export const GoogleClientId =
  "1013055659092-muq45a417pn8tofof5eh73n2mqfotuoo.apps.googleusercontent.com";

