/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import moment from "moment";
import { BankCardData, PaiementData } from "./intech.type";
import { ApiBaseUrl, AppBaseUrl, Env, IntechApikey } from "../http";
import { closeModal, getInTechPhone, getInTechTel, showModal } from "../Utils";
import { Color } from "../theme";
import { useNavigate } from "react-router-dom";

export function usePayByIntech() {
	const [loading, setLoading] = useState(false);
	const [mode, setMode] = useState<string>();
	const [externalTransactionId, setExternalTransactionId] =
		useState<string>("");

	const onPay = async (
		paiement: PaiementData,
		bankCardData?: BankCardData
	) => {
		let { apiCash, telephone, amount, transactionId } = paiement;

		setMode(apiCash);
		setLoading(true);

		try {
			let externalTransactionId =
				transactionId ?? new Date().getTime().toString();
			if (Env === "dev") {
				amount = 5;
			}
			if (amount === 1 && apiCash === "BANK_CARD_API_CASH_OUT") {
				amount = 100;
			}

			let dataSend: any = {
				amount: amount,
				phone: getInTechPhone(telephone)?.toString() ?? "",
				codeService: apiCash,
				externalTransactionId,
				callbackUrl: `${ApiBaseUrl}/api/callback/intech/`,
				apiKey: IntechApikey,
				data: {
					env: "test",
				},
				sender: "Casadeliz",
				merchantName: "Casadeliz",
				operationDescription:
					"Paiement sur la plateforme Casadeliz avec paytech",
				successRedirectUrl: `${AppBaseUrl}/paiement`,
				errorRedirectUrl: `${AppBaseUrl}/panier?status=error`,
			};

			if (apiCash === "BANK_CARD_API_CASH_OUT") {
				dataSend = {
					...dataSend,
					phone:
						getInTechTel(telephone)
							?.toString()
							.replace(/\s/g, "") ?? "",
					customerFirstName:
						bankCardData?.customerFirstName,
					customerLastName: bankCardData?.customerLastName,
					cardNumber: String(bankCardData?.cardNumber),
					cardExpireMonth: moment(
						bankCardData?.cardExpireDate
					).format("MM"),
					cardExpireYear: moment(
						bankCardData?.cardExpireDate
					)
						.format("YYYY")
						.slice(-2),
					cardType: bankCardData?.cardType,
					cardCVC: String(bankCardData?.cardCVC),
				};
			}
			const requestOptions: RequestInit = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
				},
				body: JSON.stringify(dataSend),
			};
			const res = await fetch(
				"https://api.intech.sn/api-services/operation",
				requestOptions
			).then((res) => {
				if (res.ok) return res.json();
				throw {
					data: {
						message: `Une erreur est survenue code: ${res.status}`,
					},
				};
			});
			setLoading(false);
			if (res?.error) {
				let message = "";
				if (res.data) {
					for (let [k, v] of Object.entries(res.data)) {
						if (Array.isArray(v)) {
							message = `${message}\n${k}: ${v.join(
								", "
							)}`;
						}
					}
				}
				if (message) {
					Swal.fire({
						icon: "error",
						title: message,
						showConfirmButton: false,
						timer: 5000,
					});
				} else if (res.msg) {
					Swal.fire({
						icon: "error",
						title: res.msg,
						showConfirmButton: false,
						timer: 5000,
					});
				} else {
					Swal.fire({
						icon: "error",
						title: res?.msg
							? res?.msg
							: `Erreur de statut code ${res?.code} est survenue!`,
						showConfirmButton: false,
						timer: 5000,
					});
				}
				return false;
			} else {
				const { authLinkUrl } = res.data;
				const { externalTransactionId } = res.data;
				if (authLinkUrl) {
					Swal.fire({
						icon: "success",
						iconColor: Color.success,
						title: res?.msg,
						showConfirmButton: false,
						timer: 4000,
					}).then(() => {
						window.location.href = authLinkUrl;
					});
				} else {
					closeModal("paymentMethod");
					closeModal("successModal");
					Swal.fire({
						icon: "success",
						title: res?.msg,
						showConfirmButton: false,
						iconColor: Color.success,
						timer: 5000,
					}).then(() => {
						if (externalTransactionId) {
							showModal("statusPaymentModal");
							setExternalTransactionId(
								externalTransactionId
							);
						}
					});
				}

				return true;
			}
		} catch (error: unknown) {
			setLoading(false);
			return false;
		}
	};

	return {
		onPay,
		loading,
		mode,
		externalTransactionId,
	};
}

export function useCheckStatusPaiement(
	externalTransactionId: string,
	modalId?: string
) {
	const [isFetching, setIsFetching] = useState(false);
	const [disabled] = useState(false);
	const [seconds, setSeconds] = useState<number>(20);
	const [onLoading, setOnLoading] = useState(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (isFetching) {
			const timer =
				seconds > 0
					? setInterval(() => setSeconds(seconds - 1), 1000)
					: 0;

			return () => clearInterval(timer);
		}
	}, [seconds, isFetching]);

	const onGetTransactionStatus = () => {
		setOnLoading(true);
		const raw = {
			externalTransactionId: externalTransactionId,
		};

		const requestOptions: RequestInit = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Secretkey: IntechApikey,
			},
			body: JSON.stringify(raw),
		};

		fetch(
			"https://api.intech.sn/api-services/get-transaction-status",
			requestOptions
		)
			.then((response) => response.json())
			.then((result: any) => {
				setOnLoading(false);
				console.log(result);
				if (result?.data?.status === "SUCCESS") {
					setIsFetching(false);
					closeModal("statusPaymentModal");
					modalId && closeModal(modalId);
					// Swal.fire({
					// 	icon: "success",
					// 	title: `Votre paiement effectué avec succès!`,
					// 	text: "La livraison sera effectuée dès que possible. Merci de votre confiance.",
					// 	iconColor: Color.success,
					// 	showConfirmButton: false,
					// 	timer: 3000,
					// }).then(() => {
					// 	closeModal("statusPaymentModal");
					// 	modalId && closeModal(modalId);
					// });
					navigate(`/paiement?status=success`);
					console.log("done");
				} else if (result?.data?.status === "PENDING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en attente!`,
						iconColor: Color.themeColor,
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (result?.data?.status === "REFUNDED") {
					setIsFetching(false);
					closeModal("statusPaymentModal");
					modalId && closeModal(modalId);
					Swal.fire({
						icon: "info",
						title: `Votre paiement a été remboursé!`,
						iconColor: Color.themeColor,
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (result?.data?.status === "PROCESSING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en cours de traitement!`,
						iconColor: Color.themeColor,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					console.log("no done");
					setIsFetching(false);
					closeModal("statusPaymentModal");
					modalId && closeModal(modalId);
					Swal.fire({
						icon: "error",
						title: result?.data?.errorType?.message
							? result?.data?.errorType?.message
							: `${result?.msg}`,
						showConfirmButton: false,
						timer: 3000,
					});
				}
			})
			.catch((error) => {
				setIsFetching(false);
				setOnLoading(false);
				console.log("error", error);
				Swal.fire({
					icon: "error",
					title: error?.data?.message
						? error?.data?.message
						: `Erreur de statut ${error?.status} est survenue`,
					showConfirmButton: false,
					timer: 5000,
				});
				closeModal("statusPaymentModal");
				modalId && closeModal(modalId);
			});
	};

	return {
		onLoading,
		isFetching,
		disabled,
		onGetTransactionStatus,
		seconds,
	};
}
