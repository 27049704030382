import React from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import btnLire from "../../../assets/icons/btnLire.svg";
import Forum from "../../common/Forum";
import {
	createMarkup,
	getAvatar,
	getImage,
	getName,
	deleteBaliseHtml
} from "../../../utils/Utils";
import { IconHearts } from "../../shared/Icons";
import { useArticleFromLocation } from "../../../utils/api/article/article.api";
import { ShareArticle } from "./DetailsActuVideo";
import { useToggleLikearticle } from "./useActualite";
import { ActualiteItem } from "./Actualite";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";


function DetailsActualite() {
	const { t } = useTranslation();
	const locale: any = window.sessionStorage.getItem('locale') ? window.sessionStorage.getItem('locale') : 'fr';
	const [item] = useArticleFromLocation();
	const { toggleLike } = useToggleLikearticle(item);

	const style: React.CSSProperties = {
		background: `linear-gradient(0deg, #000 -2%, rgba(0, 0, 0, 0.00) 102%), url(${getImage(
			item?.backgound_image
		)})`,
		backgroundRepeat: "no-repeat",
		backgroundSize: "contain",
		backgroundPosition: "center",
	};
	return (
		<div className="landing-page-component">
			<FrontHeader />
			<div className="page-content pb-5">
				<div
					className="casadeliz-section-hero-details-actu-container mb-4"
					style={style}
				>
					<div className="casadeliz-section-hero-details-actu-btn-container mb-5">
						<button className="casadeliz-section-hero-details-actu-btn-lire">
							<img src={btnLire} alt="btn" />
						</button>
						<span
							className="badge rounded-pill casadeliz-section-hero-details-actu-badge"
							role="button"
							onClick={toggleLike}
						>
							<IconHearts />
							{item?.likes_count}
						</span>
					</div>
					<div className="casadeliz-section-hero-details-actu-title-container mb-5">
						<h3 className="casadeliz-section-hero-details-actu-title">
							{(item?.titre && item?.titre_en) ? deleteBaliseHtml(locale == 'fr' ? item?.titre : item?.titre_en) : deleteBaliseHtml(item?.titre)}
						</h3>
					</div>
					<div className="casadeliz-section-hero-details-actu-user">
						<img
							src={getAvatar(item?.author?.avatar)}
							alt="user avatar"
							className="casadeliz-section-hero-details-actu-user-avatar"
						/>

						<div>
							<div className="casadeliz-section-hero-details-actu-username">
								{getName(item?.author)}
							</div>
							<div className="casadeliz-section-hero-details-actu-occupation">
								{t('administrators')}
							</div>
						</div>
					</div>
				</div>
				<div className="container pt-4">
					<div className="col-md-12">
						<div className="details-actu-content" >
							{(item?.content && item?.content_en) ? deleteBaliseHtml(locale == 'fr' ? item?.content : item?.content_en) : deleteBaliseHtml(item?.content)}
						</div>
						{item?.content_type === "link" && (
							<div className="details-actu-content mb-3">
								<Link target="_blank" className="custom-section-blog-btn-voir" to={item?.link } >
									{t('read_more')}
								</Link>
							</div>
						)}
						
						<div className="row custom-section-divider-forum"></div>
						<ShareArticle item={item} />
						<div className="row custom-section-divider-forum"></div>
						<div className="details-article-section-comment-title mb-4">
							{t('comments')}
						</div>
						<Forum item={item} />
						<div className="row custom-section-divider"></div>
					</div>
				</div>
				{item?.other_items && item?.other_items?.length > 0 && (
					<div className="details-actu-article-similair-container">
						<div className="details-actu-article-similair-title mb-5">
							{t('You_may_be_interested_articles')}
						</div>
						<div className="container">
							<div className="row">
								{item?.other_items?.map(
									(item) => (
										// <div className="col-md-4 mb-4"
										// 	key={
										// 		item?.slug
										// 	}>
										// 	<ActualiteItem
										// 		item={
										// 			item
										// 		}
										// 	/>
										// </div>

										<div className="col-md-4 mb-3" key={item?.slug}>
											<Link
												to={
												item?.content_type === "audio" ? 
												`/actualite-audio/${item?.slug}`
												:
												item?.content_type === "video" ?
												`/actualite-video/${item?.slug}`
												:
												item?.content_type === "link" ?
												`/actualite/${item?.slug}`
												:
												`/actualite/${item?.slug}`
												}
												className="section-blog-card-item"
												state={item}
											>
												<div className="section-blog-card-item-img-container">
												<img
													src={getImage(item?.backgound_image)}
													alt="blog"
													className="section-blog-card-item-img"
												/>
												</div>
												<div className="section-blog-card-item-description-container mb-3">
													<div className="section-blog-card-item-description mb-3">
														{(item?.content && item?.content_en) ? deleteBaliseHtml(locale == 'fr' ? item?.content?.slice(0, 100) : item?.content_en?.slice(0, 100)) : deleteBaliseHtml(item?.content?.slice(0, 100))}
													</div>
													<div className="section-blog-card-item-author">
														{getName(item?.author)}
													</div>
												</div>
											</Link>
										</div>
									)
								)}
							</div>
						</div>
					</div>
				)}
			</div>
			<FrontFooter />
		</div>
	);
}

export default DetailsActualite;
